.ordering-main{

  .ordering-header{
    height: 35vh;
    width: 100%;
    background: url("../../assets/images/temp/restaurant.jpg") center;
    display: flex;
    background-size: cover;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;


    .back-button{
      display: inline-block;
      top: 30px;
      position: absolute;
      left: 15px;
      background: #00000073;
      height: 35px;
      border-radius: 5px;
      padding: 5px;
    }

  }

  .ordering-profile-pic-container{
    background: url("../../assets/images/1.png") no-repeat bottom;
    background-size: contain;
    .order-profile-pic{
      background: url("../../assets/images/temp/profile-pic.jpg") center;
      height: 90px;
      width: 90px;
      border: 5px solid #FFFFFF;
      display: block;
      border-radius: 20px;
      background-size: cover;
      margin-bottom: -10px;
      background-color: #000;
    }
  }

  .ordering-title{
    background-color: #FFFFFF;
    margin-top: -2px;
    padding-top: 12px;
  }

  .title-text-1{
    color: #BFBFBF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }


  .title-text-2{
    color: #1D1D1D;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

}
